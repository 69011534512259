.InputContainer {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 0.25rem;
  padding-top: 0px;
}

.InputContainerInner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

.Tag {
  color: #fff;
  background-color: #0e497b;
  padding: 3px 3px 3px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 85%;
}

.TagIcon {
  color: #fff;
  background-color: #0e497b;
  padding: 3px 3px 3px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.TagIcon:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.InputIcon1 {
  color: hsl(0, 0%, 80%);
  padding: 4px;
}

.InputIcon1:hover {
  color: hsl(0, 0%, 60%);
}

.InputIcon2 {
  color: #cccccc;
  padding: 4px 13px;
  padding-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  border-left: 1px solid #cccccc;
}

.InputIcon2:hover {
  color: hsl(0, 0%, 60%);
}

.InputInput {
  border: 0px;
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  margin-top: 5px;
}

.InputInput:disabled {
  background-color: #fff;
}

.Flex {
  display: flex;
  margin-top: 5px;
}

.MultiSelectBody {
  z-index: 10000;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 5px;
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  max-height: 200px;
  overflow-y: auto;
}

.MultiSelectNoOptions {
  z-index: 10000;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 5px;
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MultiSelectBody ul {
  list-style-type: none;
  padding: 0px;
}

.MultiSelectBody ul li {
  padding: 5px 10px;
}

.buttonAdditionLocation {
  outline: none !important;
  border: none;
  background-color: transparent;
  margin-top: 5px;
  color: #0e497b;
}
