@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

// Page Properties

/* stylelint-disable no-descending-specificity, no-duplicate-selectors  */
$okta-fonts: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, 'Helvetica Neue', arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$okta-default-border-radius: 3px !default;
$okta-container-width: 400px !default;
$okta-container-min-width: 300px !default;
$okta-num-tabs: 5 !default;

// Font Size
$okta-font-size: 1rem !default;
$okta-font-size-small: ($okta-font-size * 0.875) !default;
$okta-font-size-header: ($okta-font-size * 1.25) !default;
$okta-font-size-footer: ($okta-font-size * 0.875) !default;
$okta-font-size-default: ($okta-font-size * 1.25) !default;

// Font Weight
$okta-font-weight-body: normal !default;
$okta-font-weight-header: bold !default;

// Theme Color
$okta-primary-bg-color: #fff !default;
$okta-secondary-bg-color: #f9f9f9 !default;

// Text Color
$okta-header-text-color: #5e5e5e !default;
$okta-dark-text-color: #5e5e5e !default;
$okta-medium-text-color: #777 !default;
$okta-light-text-color: #a7a7a7 !default;
$okta-link-text-color: #0074b3 !default;
$okta-placeholder-text-color: #aaa !default;
$okta-error-text-color: #e34843 !default;

// Button Color
$okta-icon-button-bg-color: #fff !default;
$okta-button-bg-color: #fbfbfb !default;
$okta-dark-button-bg-color: $blue-dark !default;
$okta-primary-button-bg-color: $blue !default;
$okta-success-button-bg-color: #4cbf9c !default;
$okta-negative-button-bg-color: #c35151 !default;
$okta-disabled-button-text-color: #aaa !default;
$okta-disabled-primary-button-text-color: #fff !default;
$okta-disabled-primary-button-bg-color: #0e497b !default;
$okta-disabled-primary-border-color: #0e497b !default;

// Social Auth Button Colors
$okta-facebook-icon-bg-color: #304879 !default; // fb brand color - #3B5998
$okta-facebook-label-bg-color: #406094 !default;
$okta-facebook-border-color: #375685 !default;
$okta-google-icon-bg-color: #d43722 !default; // google brand color - #DC4E41
$okta-google-label-bg-color: #de4937 !default;
$okta-google-border-color: #b63c33 !default;
$okta-linkedin-icon-bg-color: #04659a !default; // linkedin brand color - #0077B5
$okta-linkedin-label-bg-color: #0077b5 !default;
$okta-linkedin-border-color: #0f608f !default;
$okta-microsoft-icon-bg-color: #4294de !default; // microsoft brand color - #00A1F1
$okta-microsoft-label-bg-color: #00a1f1 !default;
$okta-microsoft-border-color: #447199 !default;

//Default Custom Button Colors
$okta-default-custom-button-background-color: #ff5f73 !default;
$okta-default-custom-button-border-color: #d6001a !default;

// Button Size
$okta-input-height: 40px !default;
$okta-button-height: 50px !default;
$okta-button-line-height: $okta-input-height - 4px !default;
$okta-button-padding: 0 15px !default;

// Input field
$okta-input-bg-color: #fff !default;
$okta-input-border-color: #bbb !default;
$okta-input-border-color-hover: #888 !default;
$okta-input-border-color-focus: $blue !default;

// Modal
$okta-modal-bg-color: #f9f9f9 !default;

// Footer

// Other

// @import 'variables';
@import '~@okta/okta-signin-widget/dist/sass/_helpers';

@mixin light-button-template($okta-bg-color) {
  @include button-template($okta-bg-color, 1%, $okta-dark-text-color, 22%, 25%);
  @include button-shadow(0.05);

  &:hover,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin dark-button-template($okta-bg-color) {
  @include button-template($okta-bg-color, 3%, #fff, 15%, 17%);
  @include button-shadow(0.15);

  &:hover,
  &:focus,
  &:active {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin button-shadow($okta-drop-shadow-opacity) {
  box-shadow: rgba(#000, $okta-drop-shadow-opacity) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

@mixin button-template(
  $okta-bg-color,
  $okta-gradient-factor,
  $okta-text-color,
  $okta-border-factor,
  $okta-border-bottom-factor
) {
  color: $okta-text-color;
  background: linear-gradient($okta-bg-color, darken($okta-bg-color, $okta-gradient-factor));
  border-color: darken($okta-bg-color, $okta-border-factor);
  border-bottom-color: darken($okta-bg-color, $okta-border-bottom-factor);

  &:hover,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    text-decoration: none;
    cursor: pointer;
    $okta-hover-bg-color: lighten($okta-bg-color, 3%);
    background-color: $okta-hover-bg-color;
    background-image: linear-gradient($okta-hover-bg-color, darken($okta-hover-bg-color, $okta-gradient-factor));
    border-color: darken($okta-bg-color, $okta-border-factor * 1.3);
    border-bottom-color: darken($okta-bg-color, $okta-border-bottom-factor * 1.2);
  }
}

// scss-lint:disable IdSelector
#okta-sign-in.auth-container {
  // scss-lint:enable IdSelector
  width: 100%;
  margin: 0;

  @media (min-width: 768px) and (max-width: 976px) {
    width: 300px;
  }

  @media (min-width: 976px) {
    width: 400px;
    margin: 70px auto 8px;
  }

  .o-form-head {
    margin-top: 0;
    font-size: $okta-font-size-default;
  }

  &.main-container {
    /* -- Fonts and Text Colors -- */
    font-family: $okta-fonts;
    color: $okta-medium-text-color;
  }

  h2,
  h3 {
    /* -- Fonts and Text Colors -- */
    font-weight: $okta-font-weight-header;
    color: $okta-header-text-color;
  }

  .okta-sign-in-header {
    /* -- Fonts and Text Colors -- */
    display: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $okta-header-text-color;
    background-color: #152f55;
  }

  .okta-form-subtitle,
  .okta-form-label {
    /* -- Fonts and Text Colors -- */
    color: $okta-medium-text-color;
  }

  .link {
    /* -- Fonts and Text Colors: links -- */
    color: $okta-medium-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $okta-medium-text-color;
    }
  }

  .inline-link {
    /* -- Fonts and Text Colors: links -- */
    color: $okta-link-text-color;

    &:link,
    &:visited,
    &:hover,
    &:active {
      /* -- Fonts and Text Colors: links -- */
      color: $okta-link-text-color;
    }
  }

  /* stylelint-disable */
  input[type='submit'],
  input[type='button'] {
    /* -- Submit Buttons' Fonts -- */
    font-family: $okta-fonts;
  }
  /* stylelint-enable */

  /* stylelint-disable-next-line */
  &.main-container {
    /* -- Main Background and Border Colors -- */
    background-color: $okta-primary-bg-color;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);

    @include mobile() {
      border-width: 0;
      box-shadow: none;
    }

    .bg-helper {
      /* -- Main Background and Border Colors -- */
      color: $okta-primary-bg-color;
    }
  }

  /* stylelint-disable-next-line */
  .okta-sign-in-header {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: #ddd;
  }

  .okta-sign-in-beacon-border {
    /* -- Main Background and Border Colors -- */
    border-color: #a7a7a7;
  }

  .okta-form-divider {
    /* -- Main Background and Border Colors -- */
    border-bottom-color: #e8e8e8;
  }

  .factor-icon,
  .qrcode-image {
    /* -- Main Background and Border Colors -- */
    border-color: #ccc;
  }

  .mfa-okta-verify-30,
  .enroll-factor-row .mfa-okta-verify {
    /* -- Factor Icons (small): Okta Verify -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/oktaVerify_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/oktaVerify_ico@2x.png');
    }
  }

  .mfa-okta-verify {
    /* -- Factor Icons (large): Okta Verify -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/oktaVerify_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Okta Verify -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/oktaVerify@2x.png');
    }
  }

  .mfa-google-auth-30,
  .enroll-factor-row .mfa-google-auth {
    /* -- Factor Icons (small): Google Authenticator -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/googleAuth_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Google Authenticator -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/googleAuth_ico@2x.png');
    }
  }

  .mfa-google-auth {
    /* -- Factor Icons (large): Google Authenticator -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/googleAuth_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Google Authenticator  -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/googleAuth@2x.png');
    }
  }

  .mfa-symantec-30,
  .enroll-factor-row .mfa-symantec {
    /* -- Factor Icons (small): Symantec VIP -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/symantec_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Symantec VIP -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/symantec_ico@2x.png');
    }
  }

  .mfa-symantec {
    /* -- Factor Icons (large): Symantec VIP -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/symantec_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Symantec VIP -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/symantec@2x.png');
    }
  }

  .mfa-rsa-30,
  .enroll-factor-row .mfa-rsa {
    /* -- Factor Icons (small): RSA SecurID -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/rsa_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): RSA SecurID -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/rsa_ico@2x.png');
    }
  }

  .mfa-rsa {
    /* -- Factor Icons (large): RSA SecurID -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/rsa_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): RSA SecurID -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/rsa@2x.png');
    }
  }

  .mfa-onprem-30,
  .enroll-factor-row .mfa-onprem {
    /* -- Factor Icons (small): OnPrem -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/onprem_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): OnPrem -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/onprem_ico@2x.png');
    }
  }

  .mfa-onprem {
    /* -- Factor Icons (large): OnPrem -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/onprem_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): OnPrem -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/onprem@2x.png');
    }
  }

  .mfa-duo-30,
  .enroll-factor-row .mfa-duo {
    /* -- Factor Icons (small): DUO -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/duo_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): DUO -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/duo_ico@2x.png');
    }
  }

  .mfa-duo {
    /* -- Factor Icons (large): DUO -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/duo_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): DUO -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/duo@2x.png');
    }
  }

  .mfa-yubikey-30,
  .enroll-factor-row .mfa-yubikey {
    /* -- Factor Icons (small): Yubikey -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/yubico_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Yubikey -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/yubico_ico@2x.png');
    }
  }

  .mfa-yubikey {
    /* -- Factor Icons (large): Yubikey -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/yubico_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Yubikey -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/yubico@2x.png');
    }
  }

  .mfa-sms-30,
  .enroll-factor-row .mfa-okta-sms {
    /* -- Factor Icons (small): SMS -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/sms_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/sms_ico@2x.png');
    }
  }

  .mfa-okta-sms {
    /* -- Factor Icons (large): SMS -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/sms_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): SMS -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/sms@2x.png');
    }
  }

  .mfa-call-30,
  .enroll-factor-row .mfa-okta-call {
    /* -- Factor Icons (small): CALL -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/voicecall_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/voicecall_ico@2x.png');
    }
  }

  .mfa-okta-call {
    /* -- Factor Icons (large): CALL -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/voicecall_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): CALL -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/voicecall@2x.png');
    }
  }

  .mfa-email-30,
  .enroll-factor-row .mfa-okta-email {
    /* -- Factor Icons (small): EMAIL -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/email_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/email_ico@2x.png');
    }
  }

  .mfa-okta-email {
    /* -- Factor Icons (large): EMAIL -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/email_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): EMAIL -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/email@2x.png');
    }
  }

  .mfa-question-30,
  .enroll-factor-row .mfa-okta-security-question {
    /* -- Factor Icons (small): Security Question -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/question_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/question_ico@2x.png');
    }
  }

  .mfa-okta-security-question {
    /* -- Factor Icons (large): Security Question -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/question_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Security Question -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/question@2x.png');
    }
  }

  .mfa-windows-hello-30,
  .enroll-factor-row .mfa-windows-hello {
    /* -- Factor Icons (small): Windows Hello -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/windowsHello_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Windows Hello -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/windowsHello_ico@2x.png');
    }
  }

  .mfa-windows-hello {
    /* -- Factor Icons (large): Windows Hello -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/windowsHello_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Windows Hello -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/windowsHello@2x.png');
    }
  }

  .mfa-u2f-30,
  .enroll-factor-row .mfa-u2f {
    /* -- Factor Icons (small): U2F -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/u2f_38x38.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): U2F -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/u2f_ico@2x.png');
    }
  }

  .mfa-u2f {
    /* -- Factor Icons (large): U2F -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/u2f_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): U2F -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/u2f@2x.png');
    }
  }

  .mfa-okta-password {
    /* -- Factor Icons (large): Password -- */
    background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/password_70x70.png');

    /* stylelint-disable-next-line */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* -- Factor Icons (high DPI): Password -- */
      background-image: url('~@okta/okta-signin-widget/dist/img/icons/mfa/password@2x.png');
    }
  }

  .button {
    /* -- Buttons' Colors -- */
    @include light-button-template($okta-button-bg-color);

    &:focus {
      border-color: $okta-input-border-color-focus;
    }
  }

  .button-dark {
    /* -- Buttons' Colors -- */
    @include dark-button-template($okta-dark-button-bg-color);
  }

  .button-primary {
    /* -- Buttons' Colors -- */
    @include dark-button-template($okta-primary-button-bg-color);
  }

  .button-success {
    /* -- Buttons' Colors -- */
    @include dark-button-template($okta-success-button-bg-color);
  }

  .button.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: $okta-disabled-button-text-color;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  .button.button-primary.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: $okta-disabled-primary-button-text-color;
    background-color: $okta-disabled-primary-button-bg-color;
    background-image: none;
    border-color: $okta-disabled-primary-button-bg-color;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active,
    &:focus {
      /* -- Buttons' Colors -- */
      cursor: default;
      background-image: none;
    }
  }

  .icon-button {
    /* -- Buttons' Colors -- */
    @include button-template($okta-icon-button-bg-color, 0%, $okta-dark-text-color, 23%, 26%);
  }

  .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    //* background: #fff; */
    border-color: $okta-input-border-color-hover;
  }

  .factors-dropdown-wrap .okta-dropdown-list {
    /* -- Dropdown Colors -- */
    // scss-lint:disable DuplicateProperty
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    // scss-lint:disable DuplicateProperty
  }

  .okta-dropdown-option {
    /* -- Dropdown Colors -- */
    //* background: transparent; */
    //* border-bottom-color: #fff; */

    // &:hover {
    //   /* -- Dropdown Colors -- */
    //   //* background: #f9f9f9; */
    // }

    &.dropdown-list-title:hover {
      /* -- Dropdown Colors -- */
      background: transparent;
    }

    a {
      /* -- Dropdown Colors -- */
      color: $okta-dark-text-color;
    }
  }

  // .okta-infobox-error {
  //   /* -- Error Infobox Colors -- */
  //   /* background-color: #fff; */
  //   /* border: 1px solid #ddd; */

  //   &:before {
  //     /* -- Error Infobox Colors -- */
  //     /* background-color: #e34843; */
  //   }

  //   .error-16:before {
  //     /* -- Error Infobox Colors: error icon -- */
  //     /* color: #fff; */

  //     /* Use the rules below to replace an error icon in error infoboxes */
  //     /* content: ''; */
  //     /* width: 16px; */
  //     /* height: 16px; */
  //     /* background-image: none; */
  //   }
  // }

  .enroll-required-factor-list {
    .enroll-factor-row-min .enroll-factor-description {
      /* stylelint-disable-next-line */
      h3 {
        /* -- Enroll required factors, factors that are not the current step -- */
        color: $okta-light-text-color;
      }
    }
  }

  .okta-form-input-field {
    /* -- Input Fields -- */
    background-color: $okta-input-bg-color;
    border-color: $okta-input-border-color;

    &:hover {
      /* -- Input Fields -- */
      border-color: $okta-input-border-color-hover;
    }

    &.focused-input {
      border-color: $okta-input-border-color-focus;
      box-shadow: 0 0 0 0.2rem rgba($okta-input-border-color-focus, 0.25);
    }

    input {
      /* -- Input Fields -- */
      font-family: $okta-fonts;
      color: $okta-dark-text-color;

      /* stylelint-disable-next-line */
      &::-webkit-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $okta-fonts;
        color: $okta-placeholder-text-color;
      }

      /* stylelint-disable-next-line */
      &::-moz-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $okta-fonts;
        color: $okta-placeholder-text-color;
      }

      /* stylelint-disable-next-line */
      &:-ms-input-placeholder {
        /* -- Input Fields: placeholder -- */
        font-family: $okta-fonts;
        color: $okta-placeholder-text-color;
      }

      /* stylelint-disable */
      &[disabled] {
        color: $okta-medium-text-color;
        -webkit-text-fill-color: $okta-medium-text-color;
        //* background: #f4f4f4; */
      }
      /* stylelint-enable */

      &[type='password'] {
        font-family: Verdana, sans-serif; /* stylelint-disable-line */
      }
    }

    /* stylelint-disable-next-line */
    placeholder {
      /* -- Input Fields: placeholder -- */
      font-family: $okta-fonts;
      color: $okta-placeholder-text-color;
    }
  }

  /* stylelint-disable-next-line */
  .input-icon::before {
    /* -- Input Fields: icons -- */
    color: $okta-light-text-color;
  }

  /* stylelint-disable-next-line */
  .input-tooltip::before {
    /* -- Input Fields: icons -- */
    color: $okta-light-text-color;
  }

  // .okta-form-label-inline {
  //   /* -- Input Fields -- */
  //   /* background-color: #f2f2f2; */
  //   /* border-color: #c8c8c8; */
  //   /* color: #999; */
  // }

  // .okta-form-input-error {
  //   /* -- Input Fields: error highlight -- */
  //   /* color: #d93934; */

  //   .error-16-small:before {
  //     /* -- Input Fields: error icon -- */
  //     /* color: #e34843; */

  //     /* Use the rules below to replace input fields error icon */
  //     /* content: ''; */
  //     /* width: 16px; */
  //     /* height: 16px; */
  //     /* background-image: none; */
  //   }
  // }

  .chzn-single {
    /* -- Input Fields: Select Input -- */
    background-color: $okta-input-bg-color;
    border-color: $okta-input-border-color;

    span {
      /* -- Input Fields: Select Input -- */
      color: $okta-medium-text-color;
    }

    &:hover {
      /* -- Input Fields: Select Input -- */
      border-color: $okta-input-border-color-hover;
    }
  }

  .chzn-with-drop {
    .chzn-single,
    .chzn-drop {
      /* -- Input Fields: Select Input -- */
      background-color: $okta-input-bg-color;
      border-color: $okta-input-border-color-hover;
    }

    // .chzn-results {
    //   li {
    //     /* -- Input Fields: Select Input -- */
    //     /* border-color: #fff; */
    //   }

    //   .highlighted {
    //     /* -- Input Fields: Select Input -- */
    //     /* background-color: #f9f9f9; */
    //   }
    // }
  }

  // .primary-auth {
  //   /* -- Page specific top level selectors -- */
  // }
  // .password-expired {
  //   /* -- Page specific top level selectors -- */
  // }
  // .password-reset {
  //   /* -- Page specific top level selectors -- */
  // }
  // .password-reset-email-sent {
  //   /* -- Page specific top level selectors -- */
  // }
  // .forgot-password {
  //   /* -- Page specific top level selectors -- */
  // }
  // .account-unlock {
  //   /* -- Page specific top level selectors -- */
  // }
  // .account-unlock-email-sent {
  //   /* -- Page specific top level selectors -- */
  // }
  // .recovery-challenge {
  //   /* -- Page specific top level selectors -- */
  // }
  // .recovery-loading {
  //   /* -- Page specific top level selectors -- */
  // }
  // .recovery-question {
  //   /* -- Page specific top level selectors -- */
  // }
  // .refresh-auth-state {
  //   /* -- Page specific top level selectors -- */
  // }

  // .enroll-choices {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-totp {
  //   /* -- Page specific top level selectors -- */
  // }
  // .barcode-totp {
  //   /* -- Page specific top level selectors -- */
  // }
  // .activate-totp {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-manual-totp {
  //   /* -- Page specific top level selectors -- */
  // }
  // .barcode-push {
  //   /* -- Page specific top level selectors -- */
  // }
  // .activate-push {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-manual-push {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-activation-link-sent {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-symantec {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-rsa {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-onprem {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-duo {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-sms {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-call {
  //   /* -- Page specific top level selectors -- */
  // }
  // .enroll-question {
  //   /* -- Page specific top level selectors -- */
  // }

  // .mfa-verify-duo {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify {
  //   /* -- Page specific top level selectors: all challenge pages except duo -- */
  // }
  // .mfa-verify-totp {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify-push {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify-totp-inline {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify-question {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify-passcode {
  //   /* -- Page specific top level selectors -- */
  // }
  // .mfa-verify-password {
  //   /* -- Page specific top level selectors -- */
  // }
}

.okta-sign-in-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $okta-fonts;
  // /* background-color: #4d4d4d; */
  // /* border-color: #303030; */

  // .qtip-content {
  //   /* -- Tooltips -- */
  //   /* color: #f3f3f3; */
  // }

  // .qtip-titlebar {
  //   /* -- Tooltips -- */
  //   /* background-color: #404040 */
  // }
}

.okta-sign-in-security-image-tooltip.qtip-custom {
  /* -- Tooltips -- */
  font-family: $okta-fonts;
  // /* background-color: #4d4d4d; */
  // /* border-color: #303030; */

  // .qtip-content {
  //   /* -- Tooltips -- */
  //   /* color: #f3f3f3; */
  // }
}
/* stylelint-emable */
