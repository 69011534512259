@import '~bootstrap/scss/_functions';
@import '../base/_variables';

/* stylelint-disable */
// a:not(.btn):not(.dropdown-item):not(.nav-link):not(.notification-link):not(.footer):not(.page-link) {
//   &:visited {
//     color: theme-color('quaternary') !important;
//   }
// }
/* stylelint-enable */

.external-connected-company-link {
  position: relative;
  bottom: 2px;
}
