@import '../base/_variables';

.paginated-list {
  &.paginated-list-unstyled {
    .paginated-list-item {
      // &:hover {
      //   background-color: rgba($black, 0.075);
      // }

      &:nth-of-type(even) {
        background-color: none;
      }
    }
  }

  &.paginated-list-scrollable {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  h5 {
    a {
      &:visited {
        /* stylelint-disable */
        color: theme-color('quaternary') !important;
        /* stylelint-enable */
      }
    }
  }

  .card-header {
    background-color: rgba($black, 0.05);
  }
}
