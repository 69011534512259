@import '~bootstrap/scss/mixins/_breakpoints';
@import '../base/_variables';

html {
  font-size: $font-size-base;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: $font-size-base;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: $font-size-base;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: $font-size-base;
  }
}

.font-size-xl {
  font-size: $font-size-xl !important;
  line-height: $line-height-xl !important;
}

.font-size-lg {
  font-size: $font-size-lg !important;
  line-height: $line-height-lg !important;
}

.font-size-md {
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
  line-height: $line-height-sm !important;
}

.font-size-xs {
  font-size: $font-size-xs !important;
  line-height: $line-height-xs !important;
}

pre {
  width: auto;
  /* stylelint-disable */
  font-family: $font-family-base !important;
  font-size: 1rem !important;
  /* stylelint-enable */
  white-space: pre-wrap;
}

$ellipsis-number-of-lines-before: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$ellipsis-number-of-lines-before: map-merge(
  (
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  ),
  $ellipsis-number-of-lines-before
);

@mixin generate-ellipsis-by-line-number($number-of-lines-before: $ellipsis-number-of-lines-before) {
  @each $number-of-lines, $number-of-lines-value in $number-of-lines-before {
    &-#{$number-of-lines} {
      /* stylelint-disable */
      display: -webkit-box;
      overflow: hidden;
      text-overflow: -o-ellipsis-lastline;
      -webkit-line-clamp: $number-of-lines;
      -webkit-box-orient: vertical;
      /* stylelint-enable */
    }
  }
}

.text-ellipsis {
  @include generate-ellipsis-by-line-number($ellipsis-number-of-lines-before);
}

.text-break-all {
  word-break: break-all;
}
