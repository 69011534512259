@import '../base/_variables';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/mixins/_breakpoints';

html {
  scroll-behavior: smooth;
}

.highlight {
  padding: 0;
  background-color: lighten(theme-color('quaternary'), 45%);
}

@include media-breakpoint-down(xl) {
  .text-truncate {
    max-width: 100%;
  }
}

.z-auto {
  z-index: auto !important;
}

.copy-icon {
  color: #152f55;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}

.copy-icon:hover {
  color: #0e497b;
}
