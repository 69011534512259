.hourRow {
  display: flex;
}

.attachmentRow {
  display: flex;
}

.attachmentButton {
  outline: none !important;
  border: none;
  background-color: transparent;
  color: #0e497b;
  margin-top: 10px;
}

.attachmentMultipleRow {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
}

.attachmentMultipleRow button {
  outline: none !important;
  border: none;
  background-color: transparent;
  color: #ff253a;
}

.attachmentMultipleRow:nth-child(even) {
  background-color: #ededed;
}
