.react-tagsinput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #238ce6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(14, 73, 123, 0.25);
  }
}

.react-tagsinput--focused {
  color: #495057;
  background-color: #fff;
  border-color: #238ce6;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(14, 73, 123, 0.25);
}

.react-tagsinput-tag {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  color: #000;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  position: relative;
  bottom: 2px;

  a {
    padding: 6px;
    position: relative;
    left: 4px;

    &:hover {
      background: #ffbdad;
      color: red;
      text-decoration: none;
    }
  }
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: hsl(0, 0%, 50%);
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 0px;
  width: 104px;
}
