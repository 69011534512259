.viewsTableRowEquest {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.viewsTableRowEquest:nth-child(even) {
  background-color: #f2f2f2;
}

.viewsTableRowEquest Div {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.viewsTableRowSwitchEquestTitle .custom-control-label {
  transform: scale(1.5);
  top: -6px;
  left: 0px;
}

.viewsTableRowSwitchEquest .custom-control-label {
  transform: scale(1.5);
  top: -17px;
  left: calc(30px);
}

.viewsTableRowSubmitEquest {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-top: 20px;
}
