.btn-group,
.dropdown {
  .dropdown-menu {
    z-index: 2000;
  }
}

.attachmentclass {
  text-overflow: ellipsis;
  overflow-x: clip;
  padding-right: 72px;
  overflow: hidden;
  white-space: nowrap;
}
