@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/_functions';
@import '../base/_variables';

$table-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$table-widths: map-merge(
  (
    50: 50px,
    75: 75px,
    100: 100px,
    125: 125px,
    150: 150px,
    175: 175px,
    200: 200px,
  ),
  $table-widths
);

@mixin table-cols($breakpoints: $grid-breakpoints, $widths: $table-widths) {
  @each $breakpoint, $breakpoint-width in $breakpoints {
    @include media-breakpoint-up($breakpoint) {
      &.table-col-#{$breakpoint} {
        display: table-cell;
      }

      @each $size, $width in $widths {
        &.table-col-#{$breakpoint}-w-#{$size} {
          width: $width;
        }
      }
    }
  }
}

.table {
  &.table-paginated {
    table-layout: fixed;

    th {
      border-top: 2px solid $table-border-color;
    }

    .table-col {
      display: none;

      a:not(.dropdown-item) {
        /* stylelint-disable */
        &:visited {
          color: theme-color('quaternary') !important;
        }
        /* stylelint-enable */
      }

      @include table-cols($grid-breakpoints, $table-widths);
    }

    .sort-asc {
      border-bottom: 2px solid $blue-dark;
    }

    .sort-desc {
      border-top: 2px solid $blue-dark;
    }
  }
}
