.ContainerUserManagementViewTopControls {
  display: flex;
  justify-content: space-between;
}

.SecondaryContainerUserManagementViewTopControls {
  display: flex;
}

.SwitchContainerUserManagement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

@media only screen and (max-width: 680px) {
  .ContainerUserManagementViewTopControls {
    flex-direction: column;
    justify-content: center;
  }

  .SecondaryContainerUserManagementViewTopControls {
    justify-content: end;
    margin-top: 10px;
  }
}

.tooltip {
  display: inline-block;
  position: relative;
  opacity: 1;
  margin-left: 10px;
  font-size: 15px;
  color: #152f55;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #152f55;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  margin-left: 12px;
  font-size: 16px;
  width: max-content;
  top: -9px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
