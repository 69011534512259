@import '~bootstrap/scss/mixins/_border-radius';
@import '~bootstrap/scss/_functions';
@import '../base/_variables';

.react-select {
  .is-selected {
    background-color: $gray-600; // $input from colors
    color: $light; // $light from colors
  }

  .react-select__control {
    @if $enable-rounded {
      @include border-radius($border-radius);
    } @else {
      border-radius: 0;
    }

    &:hover {
      border-color: $input-border-color;
    }
  }

  .react-select__control--is-focused {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;

    &:hover {
      border-color: $input-focus-border-color;
    }
  }

  // .react-select__input {
  //   width: auto;
  //   min-width: 100px;
  //   cursor: default;
  // }

  .react-select__option {
    overflow: hidden;
    color: $body-color;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: #fff;

    &:hover {
      background-color: $gray-100;
    }
  }

  .react-select__multi-value {
    color: #fff;
    background-color: theme-color('secondary');
  }

  .react-select__multi-value__label {
    color: #fff;
  }

  .react-select__multi-value,
  .react-select__multi-value__label {
    @if $enable-rounded {
      @include border-radius($border-radius);
    } @else {
      border-radius: 0;
    }
  }

  .react-select__multi-value__remove {
    @if $enable-rounded {
      @include border-left-radius(0);
      @include border-right-radius($border-radius);
    } @else {
      border-radius: 0;
    }
  }

  .react-select__menu {
    z-index: 3;
  }

  &.is-invalid {
    .react-select__control {
      border: $input-border-width solid $form-feedback-invalid-color;
    }
  }
  &.show-placeholder {
    .react-select__single-value {
      color: #808080db;
    }
  }

  &.invalid-no-border {
    .react-select__control {
      border: $input-border-width solid rgb(204, 204, 204) !important;
    }
  }

  .hideCrossButton .react-select__multi-value__remove {
    display: none;
  }
}
