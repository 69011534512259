.TableContainer {
  width: 100%;
  height: calc(100% - 25px);
  background-color: #0a3559;
  border: 1px solid #dededf;
  border-radius: 5px;
  overflow-x: auto;
}

.buttonTable {
  background-color: transparent;
  border: none;
  outline: none;
}

.buttonTable:hover {
  outline: none;
  border: none;
}
.buttonTable:focus {
  outline: none;
  border: none;
}

.Titles {
  display: flex;
  height: 40px;
  background-color: #0a3559;
  color: white;
}

.TitleColumns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
}

.divIconsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #cfcece;
  margin-right: 15px;
  cursor: pointer;
}

.iconClass {
  color: white;
}

.FirstColumn {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  max-width: 100px;
}

.FirstColumnHide {
  display: flex;
  flex: 1 0 auto;
}

.GenericRow {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid #e0e1e2;
  justify-content: flex-start;
}

.GenericColumn {
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
}

.TextLabelRow {
  color: #707070;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 6px;
}

.TextLabelRowTitle {
  display: inline-block;
  font-size: 16px;
}

.TableRowContainer {
  overflow: auto;
  height: calc(100% - 40px);
  width: fit-content;
  min-width: 100%;
  overflow-x: hidden;
  background-color: white;
  /* width: fit-content; */
}

.tableDiv {
  margin-top: 10px;
  width: 100%;
}

.PaginationDiv {
  display: flex;
  margin-top: 10px;
}

.PaginationNumber {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 3px;
  text-align: center;
  border: 2px solid #0a3559;
  width: 60px;
}

.PaginationNumber::-webkit-outer-spin-button,
.PaginationNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.PaginationNumber[type='number'] {
  -moz-appearance: textfield;
}

.PaginationIconActive {
  margin-right: 5px;
  margin-left: 5px;
  color: #00000099;
  cursor: pointer;
}

.PaginationIconInActive {
  margin-right: 5px;
  margin-left: 5px;
  color: #e0e1e2;
}

/* CheckBox */

.nonBlock {
  display: inline;
}

.marginNonBlock {
  margin-left: 26px;
}

.inputNonBlock {
  height: 0px;
  margin-bottom: 10px;
  margin-right: 0px;
  width: 8px;
}

/* The container */

.containerTable {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.containerTable input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

/* On mouse-over, add a grey background color */

.containerTable:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.containerTable input:checked ~ .checkmark {
  background-color: #0a3559;
  border-radius: 3px;
  border: 0px solid;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */

.containerTable .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show the checkmark when checked */

.containerTable input:checked ~ .checkmark:after {
  display: block;
}

@media only screen and (max-width: 680px) {
  .PaginationDiv {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .TableContainer {
    height: calc(100% - 50px);
  }
}
