#DragAndDropTable {
  width: 100%;
  margin: 0px 0px 20px;
}

.DragAndDropTableDragButton {
  width: 20px;
  cursor: move;
}

.DragAndDropTableRow {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
}

.DragAndDropTableRow:nth-child(even) {
  background-color: #ededed;
}

.DragAndDropTableEllipsisIcon {
  display: flex;
  color: grey;
}

.DragAndDropTableTimesIcon {
  display: flex;
  width: 10px;
  color: grey;
  cursor: pointer;
  justify-self: flex-end;
  margin-left: 10px;
}

.DragAndDropTableEditIcon {
  display: flex;
  width: 10px;
  color: grey;
  cursor: pointer;
  justify-self: flex-end;
}

.DragAndDropTableIconsContainer {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#DragAndDropTableEllipsisIconSpacer {
  width: 37px;
}

#DragAndDropTableDragBottomButton {
  color: #00a9db;
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 10px;
}

.DragAndDropTableRowElement {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 20px;
  // transition: width 7s ease-out;
}

.DragAndDropTableRowElement:hover {
  width: fit-content !important;
}

#DragAndDropTableColumnTItleContainer {
  display: flex;
  margin-bottom: 10px;
}

.DragAndDropTableColumnTItle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 20px;
  font-weight: bold;
}

#DragAndDropTableButtonsBottomDiv {
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
