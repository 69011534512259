.externalcredits {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .externalcredits {
    flex-direction: column !important;
  }

  .PDLButtonContainer {
    margin-top: 30px;
  }
}
