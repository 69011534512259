@import '../base/_variables';

.message-list-email,
.message-list-text {
  .col {
    p:last-of-type {
      margin-bottom: 0;
    }
  }

  & + & {
    border-top: 1px dashed $gray-400;
  }
}
