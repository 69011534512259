@import '../base/_variables';

form {
  label {
    font-size: $font-size-sm !important;
    line-height: $line-height-sm !important;
  }

  textarea {
    resize: none;
  }
}

.required-field::after {
  content: ' *';
  color: red;
}
