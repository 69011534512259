@import '~bootstrap/scss/mixins/_breakpoints';
@import '../base/_variables';

.toast-container {
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-right: 1rem;

  @include media-breakpoint-down(sm) {
    & {
      bottom: 0;
      padding-bottom: 1rem;
    }
  }

  @include media-breakpoint-up(md) {
    & {
      bottom: 0;
      padding-bottom: 3.5rem;
    }
  }

  .toast {
    max-width: 500px;

    .toast-header {
      .close {
        margin-bottom: 0.25rem;
        color: $white;
      }
    }
  }
}
