.viewsInformationDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 18px;
}

.viewsTableRowTitles {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
}

.viewsTableRowTitles Div {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewsTableRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.viewsTableRow a {
  width: 135px !important;
  display: block !important;
  line-height: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}

.viewsTableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.viewsTableRow Div {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewsTableRowContainer {
  display: flex;
  flex-direction: column;
}

.viewsTableRowSwitch {
  background-color: white;
}

.viewsTableRowSwitch .custom-control-label {
  transform: scale(1.5);
  top: -17px;
  left: calc(30px);
}

.viewsButtonsDiv {
  display: flex;
  justify-content: flex-end;
}

.viewsModalContainer {
  display: flex;
  flex-direction: column;
}

.viewsLinkText {
  color: #0e497b;
  cursor: pointer;
}

.viewsLinkText:hover {
  text-decoration: underline;
}

.chevronClassActionManagement {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInputClass {
  display: flex;
}

.PhoneInputClass .PhoneInputCountry {
  display: flex;
  flex-direction: row-reverse;
  width: 66px;
  height: 23px;
  justify-content: center;
  align-items: center;
}

.PhoneInputClass .PhoneInputInput {
  width: calc(100% - 66px);
  border: none;
  outline: none;
}

.PhoneInputClass .PhoneInputCountrySelect {
  width: 33%;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  outline: none;
}

.PhoneInputClass .PhoneInputCountryIcon {
  width: 56%;
}

.CreateUserRoleClassName {
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  &:nth-of-type(odd) {
    background-color: rgba($black, 0.05);
  }
}

.WorkActivityClassName {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 10px;
  column-gap: 5%;
  &:nth-of-type(even) {
    background-color: rgba($black, 0.05);
  }
}

.WorkActivityTitleClassName {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px 10px;
  font-weight: bold;
  column-gap: 5%;
}

.CreateUserRoleClassName p {
  margin-bottom: 0px;
}

.CreateUserRoleH6ClassName {
  margin: 20px 0px;
}

.sticky-note {
  position: fixed;
  bottom: 10px;
  width: 100%;
  max-width: 88%;
  padding: 20px;
  background-color: white;
  z-index: 10;
  box-shadow: 0px 1px 2px 2px gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky-note-button {
  border: none;
  outline: none;
  background-color: transparent;
  color: blue;
}

.sticky-note-button:hover {
  text-decoration-line: underline;
}

.sticky-note-button:focus {
  outline: none;
}
