@import 'base/_variables';
@import 'base/_stories';
@import 'base/_typography';
@import 'base/_utilities';

.app-footer {
  z-index: 10000;
}

@media (min-width: 1200px) {
  .container,
  container-xl,
  .container-lg {
    max-width: 90% !important;
  }
}
.loadingcircles.container,
.loadingcircles.container-lg,
.loadingcircles.container-xl {
  max-width: 200px !important;
}

.format-resume{
min-height: 500px;
overflow-y: scroll !important;
overflow-x: hidden;
}
