@import '~bootstrap/scss/_functions';
@import '../base/_variables';
@import '~react-day-picker/lib/style.css';

.DayPicker-Day {
  border-radius: 0.25em;

  &:hover {
    /* stylelint-disable */
    background-color: $gray-200 !important;
    /* stylelint-enable */
  }

  &--today {
    font-weight: 700;
    color: theme-color('secondary');

    &.DayPicker-Day--selected {
      /* stylelint-disable */
      color: $white;
      /* stylelint-enable */
    }
  }

  &--selected,
  &--selected:hover {
    color: $white;
    /* stylelint-disable */
    background-color: theme-color('secondary') !important;
    /* stylelint-enable */
  }

  &--disabled {
    color: $text-muted;

    &:hover {
      cursor: not-allowed;
    }
  }
}
